<template>
  <div id="app">
    <div id="nav">
      <!-- <Navbar/> -->
    </div>
    <router-view />
    <Footer/>
  </div>
</template>

<script>

// import Navbar from "./components/Navbar.vue"
import Footer from "./components/Footer.vue"


export default {
  components: { Footer },
};
</script>

<style scoped>
@font-face {
  font-family: OpenSans;
  src: local("OpenSans"), 
    url(./assets/OpenSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: OpenSans;
  src: local("OpenSans"), 
    url(./assets/OpenSans_SemiCondensed-Medium.ttf) format("truetype");
}
</style>



import { render, staticRenderFns } from "./Abt.vue?vue&type=template&id=e2ac6888&scoped=true&"
var script = {}
import style0 from "./Abt.vue?vue&type=style&index=0&id=e2ac6888&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2ac6888",
  null
  
)

export default component.exports
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#f5f5f5"}},[_c('div',{staticClass:"container d-flex container all",staticStyle:{"margin-top":"0px"}},[_c('div',{staticClass:"container information"},[_c('div',{staticClass:"infoo"},[_c('div',[_c('div',[_vm._m(0),_c('div',{staticClass:"container phone",attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('b-icon',{staticClass:"phoneIcon",attrs:{"icon":"telephone-fill","aria-hidden":"true"}}),_vm._m(1)],1)]),_c('div',[_vm._m(2),_c('div',{staticClass:"container info",attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('b-icon',{staticClass:"mailIcon",staticStyle:{"color":"black"},attrs:{"icon":"envelope-fill","aria-hidden":"true"}}),_vm._m(3)],1)]),_c('div',[_vm._m(4),_c('div',{staticClass:"container street",attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('b-icon',{staticClass:"mapIcon",attrs:{"icon":"pin-map-fill","aria-hidden":"true"}}),_vm._m(5)],1)]),_vm._m(6)])]),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container texti",attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('p',[_vm._v("Telefonnummer")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"#a5a5a5"},attrs:{"href":"tel:+41 79 887 55 00"}},[_vm._v("+41 79 887 55 00")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container texti",attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('p',[_vm._v("E-Mail Adresse")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"none","color":"#a5a5a5"},attrs:{"href":"mailto:info@mt-transport.ch"}},[_vm._v("info@mt-transport.ch")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container texti",attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('p',[_vm._v("Adresse")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('a',{staticStyle:{"text-decoration":"none","color":"#a5a5a5"},attrs:{"href":"https://www.google.com/maps/place/Gschwaderstrasse+20,+8610+Uster,+Switzerland/@47.3565141,8.7083864,17z/data=!3m1!4b1!4m6!3m5!1s0x479aa35e5471a7ad:0x42f1e5355963b6ea!8m2!3d47.3565105!4d8.7105751!16s%2Fg%2F11tgg8t1cv?hl=en"}},[_vm._v("Gschwaderstrasse 20 8610 Uster")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container texti"},[_c('p',{attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_vm._v(" Um mehr über unsere Arbeit zu erfahren, können Sie uns auch eine E-Mail schicken. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map",attrs:{"data-aos":"zoom-in","data-aos-duration":"1500"}},[_c('iframe',{staticClass:"mapp",attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2702.8403861543516!2d8.708386416028752!3d47.356514113212846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aa35e5471a7ad%3A0x42f1e5355963b6ea!2sGschwaderstrasse%2020%2C%208610%20Uster%2C%20Switzerland!5e0!3m2!1sen!2s!4v1680696592478!5m2!1sen!2s","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])
}]

export { render, staticRenderFns }
<template>
  <div style="background-color: #f5f5f5">
    <div class="container">
      <div class="container about1">
        <h1 data-aos="zoom-in" data-aos-duration="1500">
          Unsere Qualitätsversprechen an Sie:
        </h1>
      </div>
    </div>
    <div class="container" style="padding-top: 20px">
      <div data-aos="zoom-in" data-aos-duration="1500" class="row">
        <Slick v-bind="settings" class="container row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 one">
            <div class="box">
              <!-- <img src="../../assets/icon2.png" alt="Icon 1"> -->
              <h1 class="container">
                Qualitätsanspruch
              </h1>
              <p>Unser Engagement für höchste Qualitätsstandards spiegelt sich in jeder Phase unserer Dienstleistungen wider. Von der sorgfältigen Verpackung bis zur pünktlichen Lieferung setzen wir auf Präzision und Zuverlässigkeit.</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-6 one">
            <div class="box">
              <h1>Kundenzufriedenheit</h1>
              <p>Die Zufriedenheit unserer Kunden steht im Mittelpunkt unseres Handelns. Wir streben danach, ihre Erwartungen nicht nur zu erfüllen, sondern zu übertreffen, um langfristige Partnerschaften aufzubauen.</p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-6 col-6 one">
            <div class="box">
              <h1>Innovation und Anpassungsfähigkeit</h1>
              <p>Die dynamische Natur des Transportwesens erfordert ständige Innovation und Anpassungsfähigkeit. Bei MT Transport GmbH sind wir bestrebt, modernste Technologien und flexible Lösungen einzusetzen, um den sich wandelnden Bedürfnissen unserer Kunden gerecht zu werden.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 one">
            <div class="box">
              <h1>
                Sicherheit und Zuverlässigkeit
              </h1>
              <p>Die Sicherheit Ihrer Güter hat oberste Priorität. Mit hochqualifiziertem Personal und modernen Sicherheitsvorkehrungen gewährleisten wir einen sicheren und zuverlässigen Transport.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 one">
            <div class="box">
              <h1>Nachhaltigkeit:</h1>
              <p>Unser Bekenntnis zur Nachhaltigkeit zeigt sich nicht nur in umweltfreundlichen Fahrzeugen, sondern auch in einer ganzheitlichen Betrachtung unserer Geschäftspraktiken. Wir arbeiten kontinuierlich daran, ökologische Auswirkungen zu minimieren und ressourcenschonende Ansätze zu fördern.</p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-6 one">
            <div class="box">
              <h1>Kommunikation und Transparenz:</h1>
              <p>Offene Kommunikation ist der Schlüssel zu erfolgreichen Partnerschaften. Wir legen Wert auf transparente Prozesse und stehen in ständigem Austausch mit unseren Kunden, um eine reibungslose Zusammenarbeit zu gewährleisten.</p>
            </div>
          </div>
        </Slick>
        <p>Mit MT Transport GmbH wählen Sie nicht nur einen Transportdienstleister, sondern einen verlässlichen Partner, der Ihre Anforderungen mit höchster Professionalität und Sorgfalt erfüllt.</p>
      </div>
    </div>
  </div>
</template>
<script>
import Slick from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  components: {
    Slick,
  },
  data() {
    return {
      settings: {
        arrows: false,
        dots: false,
        draggable: true,
        touchMove: true,
        swipeToSlide: true,
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false,
              autoplay: true,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false,
              autoplay: true,
            },
          },

          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              speed: 500,
              autoplay: true,
              arrows: false,
            },
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
span > p::before {
  content: "•";
  margin-right: 2px;
}

.about1 h1 {
  text-align: center;
  font: normal normal bold 21px/30px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  max-width: 100%;
}
.about1 {
  padding-top: 20px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: 0px;
  margin-left: 0px;
}

.boxes {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
}

.box {
  max-width: 97%;
  min-height: 300px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  opacity: 1;
  text-align: center;
  padding: 5px;
  margin-bottom: 30px;
}

.box img {
  width: 71px;
  height: auto;
  /* margin-bottom: 10px; */
}

.box h1 {
  font: normal normal normal 18px/22px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 10px;
  min-height: 63px;
  max-width: 280px;
  text-align: center;
}
.box h2 {
  font: normal normal bold 12px/55px OpenSans;
  letter-spacing: 0px;
  color: #0054aa;
  opacity: 1;
  text-align: left;
  margin-left: 18px;
}
.box p {
  font: normal normal normal 15px/20px OpenSans;
  letter-spacing: 0.5px;
  color: #a9a9a9;
  opacity: 1;
  margin-top: 0px;
  min-height: 60px;
}
.about h1 {
  text-align: left;
  font: normal normal bold 45px/60px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  max-width: 180px;
}
.about p {
  max-width: 610px;
  text-align: left;
  font: normal normal 400 16px/19px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 5px;
  position: relative;
}
.about {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
}

@media screen and (min-width: 1201px) and (max-width: 1440px) {
  .boxes {
    justify-content: space-between;
  }
  .box {
    max-width: 80%;
    margin-bottom: 20px;
  }
  .about h1 {
    font-size: 50px;
    max-width: 800px;
    text-align: left;
  }
  .about p {
    max-width: 800px;
    text-align: left;
    margin-top: 5px;
    position: relative;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .boxes {
    justify-content: center;
  }
  .box {
    max-width: 97%;
    margin-right: 20px;
    margin-bottom: 20px;
    height: 350px;
  }
  .box:last-child {
    margin-right: 0;
  }
  .about h1 {
    font-size: 40px;
    max-width: 600px;
    text-align: left;
  }
  .about p {
    max-width: 600px;
    text-align: left;
    margin-top: 5px;
    position: relative;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .box h1 {
    margin-top: 20px;
  }
  .boxes {
    justify-content: center;
  }
  .box {
    max-width: 97%;
    margin-bottom: 20px;
    height: 320px;
  }
  .about {
    flex-direction: column;
    align-items: center;
  }
  .about h1 {
    font-size: 30px;
    max-width: none;
    text-align: center;
  }
  .about p {
    max-width: 100%;
    text-align: left;
    margin-top: 5px;
    position: static;
  }
}
@media screen and (max-width: 480px) {
  .boxes {
    flex-wrap: wrap;
    justify-content: center;
  }
  .box h1 {
    font: normal normal normal 11pt/16px OpenSans;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
    margin-top: 20px;
    min-height: 80px;
    max-width: 280px;
  }
  .box h2 {
    font: normal normal bold 12px/26px OpenSans;
    letter-spacing: 0px;
    color: #0054aa;
    opacity: 1;
    text-align: left;
    margin-left: 18px;
  }
  .box p {
    font: normal normal bold 10pt/15px OpenSans;
    letter-spacing: 0.8px;
    color: #b1b1b1;
    opacity: 1;
    margin-top: 10px;
    max-height: 60px;
    max-width: 100%;
  }
  .box {
    max-width: 100%;
    max-height: 161px;
    margin-bottom: 20px;
    margin-left: 5px;
  }
  .about {
    flex-direction: column;
    align-items: center;
  }
  .about h1 {
    font: normal normal bold 22px/30px OpenSans;
    max-width: none;
    text-align: center;
  }
  .about p {
    max-width: 306px;
    font: normal normal normal 14px/18px OpenSans;
    text-align: left;
    margin-top: 5px;
    position: static;
  }
}
</style>

<template>
  <div style="background-color: #F5F5F5;">
    <div class="header">
      <h1 data-aos="zoom-in" data-aos-duration="1500">Gallery of Work</h1>
    </div>
    <div class="container team ">
      <b-row class="row" data-aos="zoom-in" data-aos-duration="1500">
        <Slick v-bind="settings" class="container row">
        <b-col class="col-lg-3 col-md-6 col-sm-6 col-6 one">
          <img src="../../assets/1.jpg" alt="" />
        </b-col>
        <b-col class="col-lg-3 col-md-6 col-sm-6 col-6 two">
          <img src="../../assets/2.jpg" alt="" />
        </b-col>
        <b-col class="col-lg-3 col-md-6 col-sm-6 col-6 three">
          <img src="../../assets/3.jpg" alt="" />
        </b-col>
        <b-col class="col-lg-3 col-md-6 col-sm-6 col-6 four">
          <img src="../../assets/4.jpg" alt="" />
        </b-col>
        <!-- <b-col class="col-lg-3 col-md-4 col-sm-6 col-6 five">
            <img src="../../assets/11632.jpg" alt="" />
            <div>
              <p class="name">Lorem Ipsum</p>
              <p class="title">{{$t("Ceo5")}}</p>
            </div>
          </b-col>
          <b-col class="col-lg-3 col-md-4 col-sm-6 col-6 six">
            <img src="../../assets/11632.jpg" alt="" />
            <div>
              <p class="name">Lorem Ipsum</p>
              <p class="title">{{$t("Ceo6")}}</p>
            </div>
          </b-col>
          <b-col class="col-lg-3 col-md-4 col-sm-6 col-6 seven">
            <img src="../../assets/11632.jpg" alt="" />
            <div>
              <p class="name">Lorem Ipsum</p>
              <p class="title">{{$t("Ceo7")}}</p>
            </div>
          </b-col>
          <b-col class="col-lg-3 col-md-4 col-sm-6 col-6 eight">
            <img src="../../assets/11632.jpg" alt="" />
            <div>
              <p class="name">Lorem Ipsum</p>
              <p class="title">{{$t("Ceo8")}}</p>
            </div>
          </b-col> -->
          </Slick>
      </b-row>
      <!-- 
        <div>
          <div>
            <h1 class="h1">{{$t("JoinTeam")}}</h1>
            <p class="lorem">
              {{$t("JoinTeam_p")}}
            </p>
          </div>
          <div class="foto">
            <img class="img" style="object-fit: cover; height: 325px;" src="../../assets/Want to join our team.jpg" alt="" />
          </div>
        </div> -->
    </div>
  </div>
</template>
<script>
import Slick from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
export default {
  components: {
    Slick,
  },
  data() {
    return {
      settings: {
        arrows: true,
        dots: false,
        draggable: false,
        touchMove: true,
        swipeToSlide: true,
        edgeFriction: 0.35,
        infinite: true,
        speed: 500,
        autoplay: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows: false,
              autoplay: true,
            },
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              arrows:false,
              autoplay: true,
            },
          },

          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
              speed: 500,
              autoplay: true,
              arrows: false,
            },
          },
        ],
      },
    }
  }
}

</script>
<style scoped>
.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right:0px !important;
    margin-left: 0px !important;
}
.header h1{
  text-align: center;
  font: normal normal bold 45px/60px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 0px !important;
  padding-top: 30px;
  padding-bottom: 30px;
}
p {
  text-align: justify;
  font: normal normal normal 17px/30px OpenSans;
  letter-spacing: 0px;
  color: #a5a5a5;
  opacity: 1;
  margin-top: 45px;
}
img {
  /* object-fit:cover; */
  width: 97%;
  /* height: 300px; */
  /* UI Properties */
  border-radius: 12px;
  opacity: 1;
  object-fit: contain;
}

.lorem {
  /* width: 450px;
    height: 20px; */
  /* UI Properties */
  text-align: center;
  font: normal normal normal 15px/20px OpenSans;
  letter-spacing: -0.75px;
  color: #a5a5a5;
  opacity: 1;
  margin-bottom: 33px;
}

.img {
  width: 100%;
  /* height: 229px; */
  border-radius: 12px;
  opacity: 1;
  /* margin-left: 277px;
    margin-top: 32px; */
}

/* .team {
     text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center; 
  } */

h1 {
  /* width: 158px;
    height: 47px; */
  /* UI Properties */
  text-align: center;
  font: normal normal bold 35px/47px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 45px;
  /* margin-top: 45px;
    margin-bottom: 48px; */
}

/* .row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
    width: 50%;
  } */

/* Create four equal columns that sits next to each other */
/* .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  } */

/* .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    margin: 0 !important;
  } */

.name {
  /* width: 84px;
    height: 20px; */
  /* UI Properties */
  text-align: center;
  font: normal normal 600 19px/20px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
}
.team{
  padding-bottom: 50px;
}
.title {
  text-align: center;
  font: italic normal normal 12px/16px OpenSans;
  letter-spacing: -0.48px;
  color: #141414;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .name {
    /* width: 84px;
    height: 20px; */
    /* UI Properties */
    text-align: center;
    font: normal normal 600 17px/20px OpenSans;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }
  p {
    text-align: left;
    font: normal normal normal 15px/25px OpenSans;
    letter-spacing: 0px;
    color: #a5a5a5;
    opacity: 1;
    margin-top: 45px;
  }
  img {
    /* width: 182px;
      height: 112px; */
    /* UI Properties */
    /* background: transparent url("img/Rectangle 9.png") 0% 0% no-repeat
        padding-box; */
    border-radius: 7px;
    opacity: 1;
  }

  .h1 {
    /* width: 249px;
      height: 33px; */
    /* UI Properties */
    text-align: center;
    font: normal normal bold 25px/33px OpenSans;
    letter-spacing: -1px;
    color: #141414;
    opacity: 1;
  }
  .lorem {
    /* width: 360px;
      height: 16px; */
    /* UI Properties */
    text-align: center;
    font: normal normal normal 12px/16px OpenSans;
    letter-spacing: -0.6px;
    color: #a5a5a5;
    opacity: 1;
  }
  .img {
    width: 100%;
    /* height: 158px; */
    /* UI Properties */
    /* background: transparent url("img/pexels-fauxels-3184418.png") 0% 0%
        no-repeat padding-box; */
    border-radius: 8px;
    opacity: 1;
  }
  .foto {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 480px) {
  
  .header h1 {
    text-align: center;
    font: normal normal bold 22px/30px OpenSans;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
    margin-top: 0px !important;
    padding-top: 30px;
}
  .name {
    /* width: 84px;
    height: 20px; */
    /* UI Properties */
    text-align: center;
    font: normal normal 600 12px/20px OpenSans;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
  }
  p {
    text-align: center;
    font: normal normal normal 14px/25px OpenSans;
    letter-spacing: 0px;
    color: #a5a5a5;
    opacity: 1;
    margin-top: 45px;
  }
  img {
    /* width: 182px;
      height: 112px; */
    /* UI Properties */
    /* background: transparent url("img/Rectangle 9.png") 0% 0% no-repeat
        padding-box; */
    border-radius: 7px;
    opacity: 1;
  }

  .h1 {
    /* width: 249px;
      height: 33px; */
    /* UI Properties */
    text-align: center;
    font: normal normal bold 25px/33px OpenSans;
    letter-spacing: -1px;
    color: #141414;
    opacity: 1;
  }
  .lorem {
    /* width: 360px;
      height: 16px; */
    /* UI Properties */
    text-align: center;
    font: normal normal normal 12px/16px OpenSans;
    letter-spacing: -0.6px;
    color: #a5a5a5;
    opacity: 1;
  }
  img {
    width: 97%;
    /* height: 160px; */
    /* UI Properties */
    /* background: transparent url("img/pexels-fauxels-3184418.png") 0% 0%
        no-repeat padding-box; */
    border-radius: 12px;
    opacity: 1;
  }
  .foto {
    display: flex;
    justify-content: center;
  }

  .team {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  /* .eight {
      display: none;
    } */
}

</style>

<template>
    <div>
        <NavBarUber/>
        <Services/>
        <Transport/>
        <div id="appp" v-cloak>
      <transition name="fade">
        <div
          id="pagetop"
          class="fixed right-0 bottom-0"
          v-show="scY > 300"
          @click="toTop"
        >
          <svg
            style="
              background-color: grey;
              border-radius: 10px;
              cursor: pointer;
            "
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#fff"
            stroke-width="1"
            stroke-linecap="square"
            stroke-linejoin="arcs"
          >
            <path d="M18 15l-6-6-6 6" />
          </svg>
        </div>
      </transition>
    </div>
    </div>
</template>


<script>

import NavBarUber from "../components/UberUns/NavBarUber.vue"
import Services from "../components/UberUns/Services.vue"
import Transport from "../components/UberUns/Transport.vue"

export default {
    components: {NavBarUber, Services, Transport}
}

</script>
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import UberUns from "../views/UberUns.vue"
import Unser from "../views/Unser.vue"
import UnsereD from "../views/UnsereD"
import Contact from "../views/Contact"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/uberuns",
    name: "Uberuns",
    component: UberUns
  },
  {
    path: "/unser",
    name: "Unser",
    component: Unser
  },
  {
    path: "/unsered",
    name:"Unsered",
    component: UnsereD
  },
  {
    path: "/contact",
    name:"Contact",
    component: Contact
  }
 
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

<template>
  <div class="foto">
    <div id="navbar" :class="{ backgroundi: show }">
      <div class="nav-brand">
        <router-link to="/">
          <img style="height: 50px" src="../../assets/MT-logo.png" alt="" />
        </router-link>
        <b-icon-list
          scale="2"
          class="fa fa-navicon"
          v-on:click="display"
        ></b-icon-list>
      </div>

      <div class="bar-items" :class="{ hide: !show }">
        <div id="menu">
          <!-- <router-link to="">Home</router-link> -->
          <router-link to="/">Startseite</router-link>
            <!-- <router-link to="/uberuns">Über Uns</router-link>

            <router-link to="/unser">Unser Team</router-link> -->

            <router-link to="/unsered">Dienstleistungen</router-link>
            <router-link to="/contact">Kontakt</router-link>

          <!-- <RouterLink to="/contact">{{$t("Contact")}}</RouterLink> -->
          
        </div>
      </div>
    </div>
    <div class="container hi">
      <div class="text1">
        <h1>Über Uns</h1>
        <p>
          MT TRansport GmbH ist ein modernes Lieferunternehmen, das
          massgeschneiderte Auslieferungen für allerlei Unternehmen in der
          Schweiz anbietet. Unsere Firma wurde im Jahr 2023 gegründet und strebt
          Qualität unserer Leistungen und unserem Anpassungsvermögen an die
          spezifischen Anwendungen unseres Arbeitgebers an.
        </p>
      </div>

      <!-- <div class="box">
          <div class="box-item">
            <img src="../../assets/icon2.png" alt="icon 1" width="100" />
            <p>Auslieferung der Fracht</p>
          </div>
          <div class="box-item">
            <img src="../../assets/icon3.png" alt="icon 2" width="100" />
            <p>Sitz in der Schweiz</p>
          </div>
          <div class="box-item">
            <img src="../../assets/icon4.png" alt="icon 3" width="100" />
            <p>Pünktliche Lieferung</p>
          </div>
        </div> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters(["getLanguage"]),
    language() {
      if (this.getLanguage === "en") {
        return "English";
      } else if (this.getLanguage === "de") {
        return "Deutsch";
      } else if (this.getLanguage === "it") {
        return "Italian";
      } else if (this.getLanguage === "fr") {
        return "French";
      }
      return "Shqip";
    },
  },
  methods: {
    ...mapActions(["changeLanguage"]),

    changeL(lang) {
      this.changeLanguage(lang);
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang;
      }
    },

    display() {
      this.show = !this.show;
    },
  },
};
</script>

<style scoped>
@import "../../assets/style/navbar.scss";
.fa.fa-navicon {
  display: none;
}
#menu {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin: -5px;
  padding: 0;
  /* margin-top: 30px; */
}
a {
  text-decoration: none;
  white-space: nowrap;
  font: normal normal bold 15px/19px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
}
#menu a {
  padding: 50px;
}
#navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  width: 100%;
}
.hi {
  padding-top: 300px;
}
.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: white;
  /* --bs-nav-link-hover-color: var(--bs-navbar-hover-color); */
  /* --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color); */
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: white;
  text-decoration: none;
  /* transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; */
}

.navbar {
  background-color: transparent !important;
}

.navi {
  display: flex;
  flex-direction: row;
}
.nav-text {
  text-align: left;
  font: normal normal bold 15px/20px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
}

.foto {
  width: 100%;
  height: 838px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: 50% 100%;
  text-align: center;
  background-image: url(../../assets/kamioni.jpg);
  object-fit: contain;
  opacity: 1;
}

.box {
  /* position: absolute;
  top: 651px;
  left: 121px; */
  max-width: 712px;
  min-height: 115px;
  /* UI Properties */
  /* background: white 0% 0% no-repeat padding-box; */
  opacity: 1;
  display: flex;
}

.box-item {
  display: flex;
  align-items: center;
  background: white 0% 0% no-repeat padding-box;
  /* You can adjust the margin and padding as needed */
  /* margin: 10px;
  padding: 10px; */
}

.box-item img {
  max-width: 100px;
  /* You can adjust the margin as needed */
  /* margin-right: 10px; */
}

.box-item p {
  font: normal normal bold 14px/17px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
}
.text1 {
  /* position: absolute;
    top: 372px;
    left: 121px; */
}
.text1 p {
  max-width: 357px;
  text-align: left;
  font: normal normal normal 14px/22px OpenSans;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}
.text1 h1 {
  max-width: 600px;
  text-align: left;
  font: normal normal bold 52px/69px OpenSans;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}
@media (max-width: 780px) {
  #menu a {
    padding: 15px !important;
}
  .b-icon.bi {
    display: inline-block;
  }
  #navbar {
    flex-direction: column;
  }
  .backgroundi {
    background-color: rgb(2, 129, 99) !important;
}
  .backroundi {
    background: cornflowerblue;
  }

  .fa {
    display: block;
    padding: 0 25px;
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 30px;
    cursor: poOpenSans;
    margin-top: 30px;
  }

  #menu {
    flex-direction: column;
    padding: 5px 0;
  }

  a {
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
  }

  .hide {
    display: none;
  }

  .nav-brand {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 15px 0px; */
  }
  .hi {
    padding-top: 100px;
  }
}
@media (max-width: 480px) {
  #menu a {
    padding: 15px !important;
}
  .hi {
    padding-top: 300px;
  }
  .foto {
    height: 838px;
    background-position: center;
  }

  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }
  .box-item {
    width: 200px;
    /* margin: 10px; */
    text-align: center;
  }

  .box-item img {
    width: 55px;
  }

  .text1 p {
    /* max-width: 259px; */
    font:normal normal normal 13px/15px OpenSans;
    text-align: left;
    margin-bottom: 10px;
  }

  .text1 h1 {
    width: 100%;
    font:normal normal bold 24px/32px OpenSans;
    text-align: left;
  }

  .navbar-nav {
    flex-direction: row;
    justify-content: center;
  }

  .nav-link {
    padding: 0.5rem;
    font-size: 16px;
  }

  .nav-text {
    font-size: 14px;
  }
}
/* @media (min-width: 320px) and (max-width: 400px) {
  .hi {
    padding-top: 100px;
  }
  .foto {
    height: 600px;
    background-position: center;
  }

  .box {
    flex-direction: row;
    width: 100%;
  }

  .box-item {
    margin: 10px 0;
    padding: 0;
    text-align: center;
  }

  .box-item img {
    width: 50px;
  }

  .text1 p {
    width: 100%;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
  }

  .text1 h1 {
    width: 100%;
    font-size: 30px;
    text-align: center;
  }

  .navbar-nav {
    flex-direction: row;
    justify-content: center;
  }

  .nav-link {
    padding: 0.5rem;
    font-size: 14px;
  }

  .nav-text {
    font-size: 12px;
  }
} */
</style>

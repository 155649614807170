import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import en from "./i18n/en.json";
import de from "./i18n/de.json";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import AOS from 'aos';
import 'aos/dist/aos.css';

Vue.config.productionTip = false;
Vue.use(VueI18n);
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

const i18n = new VueI18n({
	locale: store.state.language.lang,
	// fallbackLocale: "de",
	messages: {
		en,
		de,
	},
});


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  mounted(){
	AOS.init();
  },
}).$mount("#app");

<template>
  <div style="background-color: #f5f5f5">
    <div class="header">
      <h1>Our Team</h1>
    </div>
    <div class="container">
      <b-row class="row">
        <b-col
          class="col-lg-3 col-md-6 col-sm-6 col-6 one"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div class="innerbox">
            <img class="container" src="../../assets/sadiku1.jpg" alt="" />
            <div>
              <p
                style="margin-top: 15px; margin-bottom: 5px"
                class="container name"
              >
                Driton Selmani
              </p>
            </div>
            <div class="container iconat">
              <p class="title">{{ $t("Ceo1") }}</p>
              <ul class="social-icons">
                <a>
                  <b-icon
                    icon="facebook"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a
                  href="https://www.instagram.com/invites/contact/?i=onl0dkrz0e1p&utm_content=qxgr27l"
                >
                  <b-icon
                    icon="instagram"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a>
                  <b-icon
                    icon="linkedin"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
                <a class="twitter">
                  <b-icon
                    icon="twitter"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col
          class="col-lg-3 col-md-6 col-sm-6 col-6 one"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div class="innerbox">
            <img class="container" src="../../assets/sadiku1.jpg" alt="" />
            <div>
              <p
                style="margin-top: 15px; margin-bottom: 5px"
                class="container name"
              >
                Driton Selmani
              </p>
            </div>
            <div class="container iconat">
              <p class="title">{{ $t("Ceo1") }}</p>
              <ul class="social-icons">
                <a>
                  <b-icon
                    icon="facebook"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a
                  href="https://www.instagram.com/invites/contact/?i=onl0dkrz0e1p&utm_content=qxgr27l"
                >
                  <b-icon
                    icon="instagram"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a>
                  <b-icon
                    icon="linkedin"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
                <a class="twitter">
                  <b-icon
                    icon="twitter"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col
          class="col-lg-3 col-md-6 col-sm-6 col-6 one"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div class="innerbox">
            <img class="container" src="../../assets/sadiku1.jpg" alt="" />
            <div>
              <p
                style="margin-top: 15px; margin-bottom: 5px"
                class="container name"
              >
                Driton Selmani
              </p>
            </div>
            <div class="container iconat">
              <p class="title">{{ $t("Ceo1") }}</p>
              <ul class="social-icons">
                <a>
                  <b-icon
                    icon="facebook"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a
                  href="https://www.instagram.com/invites/contact/?i=onl0dkrz0e1p&utm_content=qxgr27l"
                >
                  <b-icon
                    icon="instagram"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a>
                  <b-icon
                    icon="linkedin"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
                <a class="twitter">
                  <b-icon
                    icon="twitter"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col
          class="col-lg-3 col-md-6 col-sm-6 col-6 one"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div class="innerbox">
            <img class="container" src="../../assets/sadiku1.jpg" alt="" />
            <div>
              <p
                style="margin-top: 15px; margin-bottom: 5px"
                class="container name"
              >
                Driton Selmani
              </p>
            </div>
            <div class="container iconat">
              <p class="title">{{ $t("Ceo1") }}</p>
              <ul class="social-icons">
                <a>
                  <b-icon
                    icon="facebook"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a
                  href="https://www.instagram.com/invites/contact/?i=onl0dkrz0e1p&utm_content=qxgr27l"
                >
                  <b-icon
                    icon="instagram"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a>
                  <b-icon
                    icon="linkedin"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
                <a class="twitter">
                  <b-icon
                    icon="twitter"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="container">
      <b-row class="row">
        <b-col
          class="col-lg-3 col-md-6 col-sm-6 col-6 one"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div class="innerbox">
            <img class="container" src="../../assets/sadiku1.jpg" alt="" />
            <div>
              <p
                style="margin-top: 15px; margin-bottom: 5px"
                class="container name"
              >
                Driton Selmani
              </p>
            </div>
            <div class="container iconat">
              <p class="title">{{ $t("Ceo1") }}</p>
              <ul class="social-icons">
                <a>
                  <b-icon
                    icon="facebook"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a
                  href="https://www.instagram.com/invites/contact/?i=onl0dkrz0e1p&utm_content=qxgr27l"
                >
                  <b-icon
                    icon="instagram"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a>
                  <b-icon
                    icon="linkedin"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
                <a class="twitter">
                  <b-icon
                    icon="twitter"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col
          class="col-lg-3 col-md-6 col-sm-6 col-6 one"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div class="innerbox">
            <img class="container" src="../../assets/sadiku1.jpg" alt="" />
            <div>
              <p
                style="margin-top: 15px; margin-bottom: 5px"
                class="container name"
              >
                Driton Selmani
              </p>
            </div>
            <div class="container iconat">
              <p class="title">{{ $t("Ceo1") }}</p>
              <ul class="social-icons">
                <a>
                  <b-icon
                    icon="facebook"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a
                  href="https://www.instagram.com/invites/contact/?i=onl0dkrz0e1p&utm_content=qxgr27l"
                >
                  <b-icon
                    icon="instagram"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a>
                  <b-icon
                    icon="linkedin"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
                <a class="twitter">
                  <b-icon
                    icon="twitter"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col
          class="col-lg-3 col-md-6 col-sm-6 col-6 one"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div class="innerbox">
            <img class="container" src="../../assets/sadiku1.jpg" alt="" />
            <div>
              <p
                style="margin-top: 15px; margin-bottom: 5px"
                class="container name"
              >
                Driton Selmani
              </p>
            </div>
            <div class="container iconat">
              <p class="title">{{ $t("Ceo1") }}</p>
              <ul class="social-icons">
                <a>
                  <b-icon
                    icon="facebook"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a
                  href="https://www.instagram.com/invites/contact/?i=onl0dkrz0e1p&utm_content=qxgr27l"
                >
                  <b-icon
                    icon="instagram"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a>
                  <b-icon
                    icon="linkedin"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
                <a class="twitter">
                  <b-icon
                    icon="twitter"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col
          class="col-lg-3 col-md-6 col-sm-6 col-6 one"
          data-aos="zoom-in"
          data-aos-duration="1500"
        >
          <div class="innerbox">
            <img class="container" src="../../assets/sadiku1.jpg" alt="" />
            <div>
              <p
                style="margin-top: 15px; margin-bottom: 5px"
                class="container name"
              >
                Driton Selmani
              </p>
            </div>
            <div class="container iconat">
              <p class="title">{{ $t("Ceo1") }}</p>
              <ul class="social-icons">
                <a>
                  <b-icon
                    icon="facebook"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a
                  href="https://www.instagram.com/invites/contact/?i=onl0dkrz0e1p&utm_content=qxgr27l"
                >
                  <b-icon
                    icon="instagram"
                    style="color: blue"
                    aria-hidden="true"
                  ></b-icon>
                </a>
                <a>
                  <b-icon
                    icon="linkedin"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
                <a class="twitter">
                  <b-icon
                    icon="twitter"
                    aria-hidden="true"
                    style="color: blue"
                  ></b-icon>
                </a>
              </ul>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxComponent",
};
</script>

<style scoped>
.header h1 {
  text-align: center;
  font: normal normal bold 45px/60px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 0px !important;
  padding-top: 30px;
}
.row {
  /* padding-top: 30px;
  padding-bottom: 30px; */
}
.innerbox {
  max-width: 261px;
  min-height: 263px;
  /* UI Properties */
  background: white 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}
p {
  text-align: center;
  font: normal normal normal 17px/30px OpenSans;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  /* margin-top: 45px; */
}
img {
  /* object-fit:cover; */
  max-width: 261px;
  /* UI Properties */
  border-radius: 12px;
  opacity: 1;
  object-fit: contain;
  margin-top: 17px;
}

.lorem {
  /* width: 450px;
height: 20px; */
  /* UI Properties */
  text-align: center;
  font: normal normal normal 15px/20px OpenSans;
  letter-spacing: -0.75px;
  color: #a5a5a5;
  opacity: 1;
  margin-bottom: 33px;
}

.img {
  width: 100%;
  height: 229px;
  border-radius: 12px;
  opacity: 1;
  /* margin-left: 277px;
margin-top: 32px; */
}

/* .team {
 text-align: center;
display: flex;
flex-direction: column;
justify-content: center; 
} */

h1 {
  /* width: 158px;
height: 47px; */
  /* UI Properties */
  text-align: center;
  font: normal normal bold 35px/47px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
  margin-top: 45px;
  /* margin-top: 45px;
margin-bottom: 48px; */
}

/* .row {
display: flex;
flex-wrap: wrap;
padding: 0 4px;
width: 50%;
} */

/* Create four equal columns that sits next to each other */
/* .column {
flex: 25%;
max-width: 25%;
padding: 0 4px;
} */

/* .column img {
margin-top: 8px;
vertical-align: middle;
width: 100%;
margin: 0 !important;
} */

.name {
  /* width: 84px;
height: 20px; */
  /* UI Properties */
  text-align: left;
  font: normal normal bold 15px/20px OpenSans;
  letter-spacing: 0px;
  color: #141414;
  opacity: 1;
}

.iconat {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  text-align: left;
  font: italic normal normal 12px/16px OpenSans;
  letter-spacing: -0.48px;
  color: #141414;
  opacity: 1;
  margin-bottom: 0px;
}

.social-icons {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 20px;
}

.social-icons li {
  margin-right: 10px;
}

.social-icons li:last-child {
  margin-right: 0;
}

.social-icons a {
  display: block;
  width: 35px;
  /* height: 24px; */
  border-radius: 50%;
  background-color: white;
  text-align: center;
  line-height: 24px;
}

.social-icons a:hover {
  background-color: #666;
}

.social-icons i {
  font-size: 14px;
  color: white;
}

@media screen and (max-width: 768px) {
  p {
    text-align: center;
    font: normal normal normal 15px/25px OpenSans;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
    /* margin-top: 45px; */
  }
  img {
    /* width: 182px;
  height: 112px; */
    /* UI Properties */
    /* background: transparent url("img/Rectangle 9.png") 0% 0% no-repeat
    padding-box; */
    border-radius: 7px;
    opacity: 1;
  }
  h1 {
    /* width: 249px;
  height: 33px; */
    /* UI Properties */
    text-align: center;
    font: normal normal bold 25px/33px OpenSans;
    letter-spacing: -1px;
    color: #141414;
    opacity: 1;
  }

  .h1 {
    /* width: 249px;
  height: 33px; */
    /* UI Properties */
    text-align: center;
    font: normal normal bold 25px/33px OpenSans;
    letter-spacing: -1px;
    color: #141414;
    opacity: 1;
  }
  .lorem {
    /* width: 360px;
  height: 16px; */
    /* UI Properties */
    text-align: center;
    font: normal normal normal 12px/16px OpenSans;
    letter-spacing: -0.6px;
    color: #a5a5a5;
    opacity: 1;
  }
  .img {
    width: 100%;
    height: 158px;
    /* UI Properties */
    /* background: transparent url("img/pexels-fauxels-3184418.png") 0% 0%
    no-repeat padding-box; */
    border-radius: 8px;
    opacity: 1;
  }
  .foto {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 480px) {
  .header h1 {
    text-align: center;
    font: normal normal bold 22px/30px OpenSans;
    letter-spacing: 0px;
    color: #141414;
    opacity: 1;
    margin-top: 0px !important;
    padding-top: 30px;
}
  .social-icons{
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    /* margin-left: 20px; */
}
  .name{
    font: normal normal bold 15px/20px OpenSans;
    text-align: center;
    margin-top: 0px !important;
  }
  .iconat {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
  h1 {
    /* width: 249px;
  height: 33px; */
    /* UI Properties */
    text-align: center;
    font: normal normal bold 20px/33px OpenSans;
    letter-spacing: -1px;
    color: #141414;
    opacity: 1;
  }
  p {
    text-align: center;
    font: normal normal normal 14px/25px OpenSans;
    letter-spacing: 0px;
    color: black;
    opacity: 1;
    /* margin-top: 45px; */
  }
  img {
    /* width: 182px;
  height: 112px; */
    /* UI Properties */
    /* background: transparent url("img/Rectangle 9.png") 0% 0% no-repeat
    padding-box; */
    border-radius: 7px;
    opacity: 1;
  }

  .h1 {
    /* width: 249px;
  height: 33px; */
    /* UI Properties */
    text-align: center;
    font: normal normal bold 20px/33px OpenSans;
    letter-spacing: -1px;
    color: #141414;
    opacity: 1;
  }
  .lorem {
    /* width: 360px;
  height: 16px; */
    /* UI Properties */
    text-align: center;
    font: normal normal normal 12px/16px OpenSans;
    letter-spacing: -0.6px;
    color: #a5a5a5;
    opacity: 1;
  }
  img {
    width: 100%;
    height: 160px;
    /* UI Properties */
    /* background: transparent url("img/pexels-fauxels-3184418.png") 0% 0%
    no-repeat padding-box; */
    border-radius: 8px;
    opacity: 1;
  }
  .foto {
    display: flex;
    justify-content: center;
  }

  .team {
    display: flex;
    flex-direction: column;
    align-content: center;
  }
  /* .eight {
  display: none;
} */
}

</style>
